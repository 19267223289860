import { TweenMax, TimelineMax } from "gsap/TweenMax";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { Circ } from "gsap";
import Flickity from 'flickity';
import ImagesLoaded from 'imagesloaded';
import Highcharts from 'highcharts';
const MG = {};
window.MG = MG;
(() => {
  const scrollToPlugin = ScrollToPlugin;
  function scrollTo(elt, target) {
    TweenMax.to(window, 1, {
      scrollTo: elt.getBoundingClientRect().top + window.scrollY,
      onComplete: () => {
        if (target !== undefined) {
          window.location.hash = target;
        }
      },
    });
  }

  function scrollToId(href) {
    if (href !== undefined) {
      const target = href.split('#')[1];
      if (document.getElementById(target) !== null) {
        scrollTo(document.getElementById(target), target);
        MG.mainNav.close('SKIPANIMATION');
      }
    }
  }

  class MainNav {
    constructor() {
      if (document.querySelector('.main-nav')) {
        this.DOM = { elt: document.querySelector('.main-nav') };
        this.DOM.openBtn = document.querySelector('.burger-btn');
        this.DOM.closeBtn = document.querySelector('.btn--close');
        this.DOM.menuItems = this.DOM.elt.querySelectorAll('.menu__item:not(.level)');
        this.DOM.menuLinks = this.DOM.elt.querySelectorAll('.menu__item a');
        this.tl = new TimelineMax({
          paused: true,
          onReverseComplete: () => {
            this.onAnimReverseComplete();
          },
          onComplete: () => {
            this.onAnimComplete();
          },
        });
        this.isMenuOpen = false;
        this.initEvents();
        this.setScene();
      }
    }

    initEvents() {
      this.DOM.openBtn.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.isMenuOpen) {
          this.toogle('close');
        } else {
          this.toogle('open');
        }
      });
      this.DOM.menuLinks.forEach((elt) => {
        elt.addEventListener('click', (e) => {
          const href = e.currentTarget.getAttribute('href');
          const target = href.split('#')[1];
          if (document.getElementById(target) !== null) {
            e.preventDefault();
            scrollToId(href);
          }
          this.highlightLink(elt);
        });
      });
    }

    toogle(action) {
      this.isMenuOpen = action === 'open';
      if (this.isMenuOpen) {
        this.open();
      } else {
        this.close();
      }
    }

    open() {
      this.DOM.elt.classList.add('is-open');
      this.DOM.openBtn.classList.add('is-active');
      this.tl.play();
    }

    close(state) {
      if (state !== undefined && state === 'SKIPANIMATION') {
        console.log('skip animation');
        this.DOM.elt.classList.remove('is-open');
        this.DOM.openBtn.classList.remove('is-active');
      } else {
        this.tl.reverse().timeScale(2);
      }
      this.isMenuOpen = false;
    }

    highlightLink(current) {
      this.DOM.menuLinks.forEach((sub) => {
        sub.classList.remove('is-active');
      });
      current.classList.add('is-active');
    }

    setScene() {
      this.tl.staggerFrom(this.DOM.menuItems, 0.3, {
        delay: 0.3, x: '15px', alpha: 0, ease: Circ.easeOut,
      }, 0.2);
    }

    onAnimReverseComplete() {
      this.DOM.elt.classList.remove('is-open');
      this.DOM.openBtn.classList.remove('is-active');
    }

    onAnimComplete() {
      this.DOM.menuItems.forEach((elt) => {
        elt.removeAttribute('style');
      });
    }
  }

  class HC {
    constructor(elt) {
      if (document.querySelector(elt)) {
        this.DOM = { elt: document.querySelector(elt) };
        const settings = this.DOM.elt.getAttribute('data-settings');
        this.settings = JSON.parse(settings);
        console.log(settings, this.settings)

        this.init();
      }
    }

    init() {
      Highcharts.chart(this.DOM.elt, this.settings);
    }
  }

  class Carousels {
    constructor(eltWrapper) {
      this.DOM = { eltWrapper};
      this.DOM.elt = this.DOM.eltWrapper.querySelector('.js-carousel');
      this.init();
    }

    init() {
      var self = this;
      const flktyData = this.DOM.elt.getAttribute('data-flickity');
      self.flck = null;
      if (flktyData) {
        const flktyOptions = JSON.parse(flktyData);
        self.flck = new Flickity(self.DOM.elt, flktyOptions);
      } else {
        self.flck = new Flickity(self.DOM.elt);
      }

      if (self.DOM.eltWrapper.querySelector('.btn--next') && self.DOM.eltWrapper.querySelector('.btn--prev')) {
        self.DOM.eltWrapper.querySelectorAll('.btn--next').forEach((btn) => {
          btn.addEventListener('click', (e) => {
            e.preventDefault();
            self.flck.next(true, false);
          })
        });
        self.DOM.eltWrapper.querySelectorAll('.btn--prev').forEach((btn) => {
          btn.addEventListener('click', (e) => {
            e.preventDefault();
            self.flck.previous(true, false);
          })
        });
      }

      if (this.DOM.elt.classList.contains('c-cards--expandable')) {
        this.DOM.elt.querySelectorAll('.c-card').forEach((card) => {
          card.querySelector('.btn-toggle').addEventListener('click', (e) => {
            const isActive = card.classList.contains('is-expanded');
            if (isActive) {
              card.classList.remove('is-expanded');
              card.querySelector('.c-card__inner').classList.add('bg-grey-lt');
              card.querySelector('.c-card__inner').classList.remove('bg-black');
              e.target.innerHTML = 'Read full report';
            } else {
              card.classList.add('is-expanded');
              card.querySelector('.c-card__inner').classList.add('bg-black');
              card.querySelector('.c-card__inner').classList.remove('bg-grey-lt');
              e.target.innerHTML = 'Close';
            }
            self.flck.reposition();
            self.flck.resize();
          })
        })
      }
    }
  }

  function initEvents() {

  }

  class Intro {
    constructor(elt) {
      if (document.querySelector(elt)) {
        const self = this;
        self.DOM = { elt: document.querySelector(elt) };
        self.isIntroComplete = false;
        self.tl0 = new TimelineMax({
          paused: true,
          delay: 1,
          onComplete: () => {
            self.isIntroComplete = true;
          },
        });
        self.setScene();
        self.animateIn();
      }
    }
    setScene(cb) {
      const self = this;
      self.tl0.from(self.DOM.elt.querySelectorAll('.line'), 0.6, { width: 0, alpha: 0, ease: Circ.easeIn });
      self.tl0.staggerFrom(self.DOM.elt.querySelectorAll('div'), 0.6, { y: 30, alpha: 0, ease: Circ.easeOut }, 0.1);
    }

    animateIn() {
      this.isIntroComplete = false;
      this.tl0.play();
    }
  }
  
  function init() {
    MG.mainNav = new MainNav();
    MG.hC = new HC('.highcharts-wrapper > div');
    if(document.querySelector('.js-carousel-wrapper')) {
      MG.carousels = [];
      document.querySelectorAll('.js-carousel-wrapper').forEach((elt) => {
        MG.carousels.push(new Carousels(elt));
      });
    }
    MG.intro = new Intro('.c-score');
    initEvents();
  }

  document.addEventListener('DOMContentLoaded', () => {
    init();
  });
})();